<template>
  <div>
    <froala :tag="'textarea'" :config="config" v-model="content"></froala>
  </div>
</template>
<script>

  import SERVICE_URLS from '../../api/service.url'
  import VueCookie from 'vue-cookie'
  import * as types from '../../store/mutation-types'

  // Require Froala Editor css files.
  require('../../plugins/froala-editor/froala_editor.pkgd.css')
  require('../../plugins/froala-editor/froala_style.css')
  require('../../plugins/froala-editor/third-library/codemirror.min.css')
  require('../../plugins/froala-editor/third-library/codemirror.min')
  require('../../plugins/froala-editor/third-library/xml.min')
  require('../../plugins/froala-editor/froala_editor.pkgd.min')
  require('../../plugins/froala-editor/language/zh_cn')

  export default {
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    watch: {
      value (val) {
        this.content = val
      }
    },
    data () {
      const xAuthToken = VueCookie.get(types.DEFAULT_X_AUTH_TOKEN)
      if (!xAuthToken) {
        this.$message.error('上传凭证丢失，请重新登录！')
        return
      }
      return {
        config: {
          events: {
            'froalaEditor.initialized': (e, editor) => {

            },
            'froalaEditor.keyup': () => {
              this.lastEditRange = getSelection().getRangeAt(0)
            },
            'froalaEditor.click': () => {
              this.lastEditRange = getSelection().getRangeAt(0)
            },
            'froalaEditor.contentChanged': () => {
              this.$nextTick(() => {
                this.$emit('input', this.content)
                this.$emit('change', this.content)
              })
            }
          },
          // Base setting
          toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'outdent', 'indent',
            'subscript', 'superscript',
            '|', 'align', 'lineHeight', 'fontFamily', 'fontSize', 'color',
            '|', 'insertLink', 'insertImage', 'insertFile', 'insertTable',
            '|', 'undo', 'redo', 'clearFormatting', 'fullscreen', 'html'],
          toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'outdent', 'indent',
            'subscript', 'superscript',
            '|', 'align', 'lineHeight', 'fontFamily', 'fontSize', 'color',
            '|', 'insertLink', 'insertImage', 'insertFile', 'insertTable',
            '|', 'undo', 'redo', 'clearFormatting', 'fullscreen', 'html'],
          toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'outdent', 'indent',
            'subscript', 'superscript',
            '|', 'align', 'lineHeight', 'fontFamily', 'fontSize', 'color',
            '|', 'insertLink', 'insertImage', 'insertFile', 'insertTable',
            '|', 'undo', 'redo', 'clearFormatting', 'fullscreen', 'html'],
          toolbarButtonsXS: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'outdent', 'indent',
            'subscript', 'superscript',
            '|', 'align', 'lineHeight', 'fontFamily', 'fontSize', 'color',
            '|', 'insertLink', 'insertImage', 'insertFile', 'insertTable',
            '|', 'undo', 'redo', 'clearFormatting', 'fullscreen', 'html'],
          charCounterCount: false,
          heightMin: 300,
          placeholderText: '输入内容......',
          pluginsEnabled: ['lists', 'table', 'image', 'file', 'link', 'fontSize', 'fontFamily',
            'colors', 'codeView', 'fullscreen', 'align', 'insertLink'],
          language: 'zh_cn',
          fontFamily: {
            'SimSun,sans-serif': '宋体',
            'FangSong,sans-serif': '仿宋',
            'FangSong_GB2312,sans-serif': '仿宋_GB2312',
            'Microsoft YaHei,sans-serif': '微软雅黑',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            '\'Times New Roman\',Times,serif': 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana'
          },
          zIndex: 6,
          // The offset of the sticky toolbar from top of the page.
          // If toolbarBottom option is used, then this option will define the offset of the toolbar from the bottom of the page.
          toolbarStickyOffset: 64,
          // html 预览
          codeMirror: true,
          // 图片上传
          imageUploadURL: SERVICE_URLS.document.froalaUpload.path + '?' + types.DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken,
          imageMaxSize: 1024 * 1024 * 10,
          imageDefaultWidth: 500,
          imageUploadRemoteUrls: true,
          // 文件上传配置
          fileMaxSize: 1024 * 1024 * 1024,
          fileUploadURL: SERVICE_URLS.document.froalaUpload.path + '?' + types.DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
        },
        lastEditRange: null,
        content: this.value || ''
      }
    },
    components: {}
  }
</script>
